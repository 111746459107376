.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.loading-spinner {
    border: 2px solid #ffffff;
    border-top: 2px solid #363636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}