:root {
  --selective-yellow: hsl(42, 94%, 55%);
  --eerie-black-1: hsl(0, 0%, 9%);
  --eerie-black-2: hsl(180, 3%, 7%);
  --quick-silver: hsl(0, 0%, 65%);
  --radical-red: hsl(351, 83%, 61%);
  --light-gray: hsl(0, 0%, 80%);
  --isabelline: hsl(36, 33%, 94%);
  --gray-x-11: hsl(0, 0%, 73%);
  --kappel_15: hsla(170, 75%, 41%, 0.15);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(0, 0%, 50%);
  --black_80: hsla(0, 0%, 0%, 0.8);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --black_50: hsla(0, 0%, 0%, 0.5);
  --black_30: hsla(0, 0%, 0%, 0.3);
  --kappel: hsl(170, 75%, 41%);
  --white: hsl(0, 0%, 100%);

  --gradient: linear-gradient(-90deg,
      hsl(151, 58%, 46%) 0%,
      hsl(170, 75%, 41%) 100%);

  --ff-league_spartan: "League Spartan", sans-serif;
  --ff-poppins: "Poppins", sans-serif;
  --fs-1: 3.1rem;
  --fs-2: 2.4rem;
  --fs-3: 1.7rem;
  --fs-4: 1.35rem;
  --fs-5: 1.125rem;
  --fs-6: 1rem;
  --fs-7: 0.9rem;
  --fs-8: 0.8rem;
  --fw-500: 500;
  --fw-600: 600;

  --section-padding: 75px;

  --shadow-1: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
  --shadow-2: 0 10px 30px hsla(0, 0%, 0%, 0.06);
  --shadow-3: 0 10px 50px 0 hsla(220, 53%, 22%, 0.1);

  --radius-pill: 500px;
  --radius-circle: 50%;
  --radius-3: 3px;
  --radius-5: 5px;
  --radius-10: 10px;

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

a,
span,
data,
input,
ion-icon {
  display: block;
}

a {
  color: #fff;
  text-decoration: none;
}

li {
  list-style: none;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-poppins);
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
}

::selection {
  color: none;
  background: none;
}

:focus-visible {
  outline-offset: 4px;
}

::-webkit-scrollbar {
  display: none;
  width: 11px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background: #2DBAA3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2AB983;
  cursor: pointer;
}

button {
  cursor: pointer;
}


/* REUSED STYLES */
.container {
  padding-inline: 15px;
}

.section {
  padding-block: var(--section-padding);
}

.shape {
  position: absolute;
  display: none;
}

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h1,
.h2,
.h3 {
  color: var(--eerie-black-1);
  font-family: var(--ff-league_spartan);
  line-height: 1;
}

.h1,
.h2 {
  font-weight: var(--fw-600);
}

.h1 {
  font-size: var(--fs-1);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-500);
}

.section-title {
  --color: var(--radical-red);
  text-align: center;
  line-height: 1.2;
}

.section-title .span {
  display: inline-block;
  color: var(--color);
}

.btn {
  background-color: var(--kappel);
  color: var(--white);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-4);
  display: flex;
  align-items: center;
  gap: 7px;
  max-width: max-content;
  padding: 10px 20px;
  border-radius: var(--radius-5);
  overflow: hidden;
}

.has-before,
.has-after {
  position: relative;
  z-index: 1;
}

.has-before::before,
.has-after::after {
  position: absolute;
  content: "";
}

.btn::before {
  inset: 0;
  background-image: var(--gradient);
  z-index: -1;
  border-radius: inherit;
  transform: translateX(-100%);
  transition: var(--transition-2);
}

.btn:is(:hover, :focus)::before {
  transform: translateX(0);
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  overflow: hidden;
  display: flex;
  border-radius: 30px;
}


.section-subtitle {
  font-size: var(--fs-5);
  text-transform: uppercase;
  font-weight: var(--fw-500);
  letter-spacing: 1px;
  text-align: center;
  margin-block-end: 15px;
}

.section-text {
  font-size: var(--fs-5);
  text-align: center;
  margin-block: 15px 25px;
}

.grid-list {
  display: grid;
  gap: 30px;
}

.link {
  text-decoration: none;
}

#img-cover {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 40px;
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
}

#progressBar {
  background: #09bd78;
  transform-origin: top;
  transform: scale(0, 0);
  opacity: 0;
}

#progressBar,
#progressBarContainer {
  height: 5px;
}

@media(max-width:768px) {
  #progressBar,
  #progressBarContainer {
    height: 3px;
  }
}